// import styled, { css, keyframes } from "styled-components";
import styled from "styled-components";
import { SvgProps } from "./types";

// const rotate = keyframes`
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// `;

// const spinStyle = css`
//   animation: ${rotate} 2s linear infinite;
// `;

const Svg = styled.svg<SvgProps>`

`;

Svg.defaultProps = {
  color: "text",
  width: "100%",
  xmlns: "http://www.w3.org/2000/svg",
  spin: false,
  className: "animation"
  //   xmlns:xlink:"http://www.w3.org/1999/xlink", 

};

export default Svg;
