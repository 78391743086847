import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { APIContext } from "../../../apiContext";


const SearchStories: React.FC = () => {
    const { searchRes, showWords, url } = useContext(APIContext);
    const [showStories, setShowStories] = useState(false)

    let navigate = useNavigate();

    useEffect(() => {
        if (searchRes.length > 1) {
            setShowStories(true)
        }
        else if (searchRes.length < 1) {
            setShowStories(false)
        }
    }, [searchRes])

    return (
        <MainDiv>
            <Container>
                {showStories && <div className="sub-container row">
                    {searchRes.map((item: any, index: any) => (
                        <div className="p-3 text-center item col-lg-3 col-md-2 col-sm-1 col-xs-1">
                            <img src={url + item.url}
                                alt="Search Stories Data"
                                onClick={() => {
                                    navigate(`/video/${item.id}`)
                                    localStorage.setItem("type", "search")
                                }}
                            />
                            {showWords && <div className='text-light text-center d-flex justify-content-center'>{item.title}</div>}

                        </div>
                    ))

                    }

                </div>}
            </Container>
        </MainDiv>
    );
}

export default SearchStories;

const MainDiv = styled.div`
padding-top: 20px;
padding-bottom: 20px;
background: grey;
// max-width: 1400px;
margin-left: auto;
margin-right: auto;

img{
    width: 200px;
    height: 200px;
    cursor: pointer;
}

`
