import { useContext } from "react";
import { APIContext } from "../apiContext";

export const useGetMenuStories = (index: any) => {

  const { apiRes } = useContext(APIContext)

  var res = apiRes.filter((number: any) => number.id === parseInt(index)).map((number: any) => {
    return number.stories
  })

  return res;
  
}