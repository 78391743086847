import { createGlobalStyle } from "styled-components";

const GlobalStyle=createGlobalStyle`

  body{
    background: black;
  }

  .animation{
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */

    @keyframes fadein {
      from {
          opacity:0;
      }
      to {
          opacity:1;
      }
    }
    @-moz-keyframes fadein { /* Firefox */
      from {
          opacity:0;
      }
      to {
          opacity:1;
      }
    }
    @-webkit-keyframes fadein { /* Safari and Chrome */
      from {
          opacity:0;
      }
      to {
          opacity:1;
      }
    }
    @-o-keyframes fadein { /* Opera */
      from {
          opacity:0;
      }
      to {
          opacity: 1;
      }
    }
  }

  .pageLoaderUPs {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02020280;
  }

  // img{
  //   cursor: pointer;
  // }

  // image{
  //   cursor: pointer;
  // }

  .custom-prevbtn{
    height: 50px;
      position: absolute;
      cursor: pointer;
    opacity: 1;
      transform: rotate(180deg);
        left: -2%;
        top: 37%;
        @media screen and (max-width: 1440px) {
            left: -1%;
          }
        @media screen and (max-width: 1280px) {
          left: 2%;
        }
        @media screen and (max-width: 1100px) {
          left: -3%;
        }
        @media screen and (max-width: 600px) {
          left: -10%;
        }
        @media screen and (max-width: 340px) {
          left: -20%;
        }
        &.inactive{
          display: none !important;
        }
    }
    
    .custom-nextbtn{
      height: 50px;
      cursor: pointer;
      opacity: 1;
      position: absolute;
      top:37%;
        right: 0;
        left: 102%;
        color: red;
        // bottom: 50%;
        @media screen and (max-width: 1440px) {
            left: 97%;
          }
          @media screen and (max-width: 1366px) {
            left: 95%;
          }
        @media screen and (max-width: 1280px) {
          left: 93%;
        }
        &.inactive{
          display: none !important;
        }
    }

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px grey; 
      border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#288b26, #3a9936, #4ba846);
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #288b26; 
    }
  }

  
`

export default GlobalStyle;