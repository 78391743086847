import styled from "styled-components";
import { useParams } from "react-router-dom";
import TeamDetailsSlider from "./TeamDetailsSlider";
import bg from "../../../assets/images/wavy-bg.svg";
import { useEffect, useState } from "react";
import TeamDetailContainer from "./TeamDetailContainer";
import { useGetTeamDetails } from "../../../hooks/useGetTeamDetails";
import { useGetCharacters } from "../../../hooks/useGetCharacters";

const TeamDetails: React.FC = () => {
  const { id }: any = useParams();

  //State Manage to re-render big slider on every click
  const [showSlider, setShowSlider] = useState<boolean>(true);

  //Get data of the Items (Team Details)
  const teamDetails = useGetTeamDetails();

  //Get data of the characters array within the current Item
  const charData = useGetCharacters(id);

  useEffect(() => {
    const getData = () => {
      //If data is present, character slider will be displayed else hidden
      if (charData[0]?.length > 0) {
        setShowSlider(true);
      } else {
        setShowSlider(false);
      }
    };
    getData();
  }, [id]);

  useEffect(() => {
    //Store item in local storage to see the direction of user -> going back or forward
    localStorage.setItem("navigate", "forward");
  }, []);

  return (
    <MainDiv>
      {/* Top Main Container on First click of home page -> Items array*/}
      <TeamDetailContainer data={teamDetails} />

      {/* Show slider of characters array data */}
      <TeamDetailsSlider data={charData} showSliderPrev={setShowSlider} />
    </MainDiv>
  );
};

export default TeamDetails;

const MainDiv = styled.div`
  background-image: url(${bg});
  height: calc(100vh - 97px);
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    /* Firefox */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fadein {
    /* Opera */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
