import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow from "../../../assets/images/arrowRight.svg";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useGetStorySets } from "../../../hooks/useGetStorySets";
import { APIContext } from "../../../apiContext";
import blackbg from "../../../assets/images/HexagonBlack.svg";

//Left Arrow for carousel
function SamplePrevArrow(props: any) {
  return (
    <img
      src={arrow}
      {...props}
      className={`custom-prevbtn ${props.onClick === null ? "inactive" : ""}`}
    ></img>
  );
}

//Right Arrow for carousel
function SampleNextArrow(props: any) {
  return (
    <img
      src={arrow}
      {...props}
      className={`custom-nextbtn ${props.onClick === null ? "inactive" : ""}`}
    ></img>
  );
}

const StorySetSlider: React.FC = () => {
  //Params from the URL
  const { id, chId, stId }: any = useParams();

  //Get data from API Context (Words under images, base url for images)
  const { showWords, url } = useContext(APIContext);

  //State manage for carousel show/hide
  const [showSlider, setShowSlider] = useState<boolean>(true);

  //State manage to show borders behind active image
  const [prevId, setPrevId] = useState<number>();

  //Get response from API
  const storySetData = useGetStorySets(id, chId);

  let navigate = useNavigate();

  const [routeCheck, setRouteChec] = useState(localStorage.getItem("navigate"));

  useEffect(() => {
    //Rerender slider on change of Item ID or Character ID
    const getData = () => {
      setShowSlider(true);
    };
    getData();
  }, [id, chId]);

  useEffect(() => {
    //if story ID is present in URL (refresh case / navigated backward), hide bigger slider

    if (stId) {
      setShowSlider(false); //Hide Slider, small slider to appear
    }

    if (routeCheck === "backward") {
      setShowSlider(false); //Hide Slider, small slider to appear
      navigate(`/stories/${id}/${chId}/${stId}`); //Show child route (VideoSlider)
    }
  }, [stId]);

  //Settings for React Slick Carousel Before Click
  var settings = {
    dots: false,
    slidesToShow: 5,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  //Settings for React Slick Carousel After Click
  var settingsDiv = {
    dots: false,
    slidesToShow: 8,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    // arrows: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  //Handle behaviour on image clicks
  const handleClick = (index: any) => {
    setPrevId(parseInt(stId)); //Set id for previous selected item for shadow/border
    setShowSlider(false); //Hide story set bigger slider
    navigate(`/stories/${id}/${chId}/${index}`); //Show child route (VideoSlider)
  };

  return (
    // There are four cases here
    //    - Big Slider which appears on the click of Character Array (Team Details Slider)
    //          1- Storysets within the characters array
    //    - Big Slider which appears on the click of Main Items Array (Team Detail Container)
    //          2- Storysets where characters array is empty
    //    - Small Slider which appears on the click of Story Set Array
    //          3- Storysets within the characters array
    //    - Small Slider which appears on the click of Story Set Array
    //          4- Storysets where characters array is empty

    <>
      <Container>
        <div className="sub-container">
          {/* Show Carousel initially and hide on click */}
          {showSlider && (
            <Slider {...settings}>
              {storySetData.length > 0 &&
                storySetData[0].map((char: any, index: any) =>
                  char.length > 0 ? ( //Case 1
                    //nested mapping to get storysets from characters array
                    char.map((item: any, index: any) => (
                      <div key={index} className="item">
                        <img
                          src={url + item.url}
                          alt="Story Set Data"
                          onClick={() => {
                            item.stories.length > 0 && handleClick(item.id);
                          }}
                          style={{
                            opacity:
                              item.stories.length > 0 //show faded image if no data exists
                                ? "1"
                                : "0.5",
                            cursor:
                              item.stories.length > 0 //change curser shape if no data exists
                                ? "pointer"
                                : "not-allowed",
                          }}
                        />

                        {/* Div to show text under images - controlled by switch button on left menu*/}
                        {showWords && (
                          <div className="text-dark text-center d-flex justify-content-center">
                            {item.title}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    //Case 2
                    // story sets where characters array is empty
                    <div key={index} className="item">
                      <img
                        src={url + char.url}
                        alt="Story Set Data"
                        onClick={() => {
                          char.stories.length > 0 && handleClick(char.id);
                        }}
                        style={{
                          opacity:
                            char.stories.length > 0 //show faded image if no data exists
                              ? "1"
                              : "0.5",
                          cursor:
                            char.stories.length > 0 //change curser shape if no data exists
                              ? "pointer"
                              : "not-allowed",
                        }}
                      />

                      {/* Div to show text under images - controlled by switch button on left menu*/}
                      {showWords && (
                        <div className="text-dark text-center d-flex justify-content-center">
                          {char.title}
                        </div>
                      )}
                    </div>
                  )
                )}
            </Slider>
          )}

          {/* Carousel to be replaced with smaller carousel div */}
          {!showSlider && (
            <StorySetDiv className="animation">
              {
                <div className="sub-sliderContainer">
                  <Slider {...settingsDiv}>
                    {storySetData.length > 0 &&
                      storySetData[0].map((char: any, index: any) =>
                        char.length > 0 ? (
                          //Case 3
                          char.map((item: any, index: any) => (
                            <div
                              key={index}
                              className="storyset-container"
                              onClick={() => {
                                item.stories.length > 0 && handleClick(item.id);
                              }}
                            >
                              {/* Image to show active / clicked storyset */}
                              <img
                                className={
                                  parseInt(stId) === item.id
                                    ? "backgroundImg fadein"
                                    : `${
                                        prevId === item.id
                                          ? "backgroundImg fadeout"
                                          : "d-none"
                                      }`
                                }
                                src={blackbg}
                                alt="Active"
                              ></img>

                              <img
                                className="imageData"
                                src={url + item.url}
                                alt="Story Set Data"
                                style={{
                                  opacity:
                                    item.stories.length > 0 //show faded image if no data exists
                                      ? "1"
                                      : "0.5",
                                  cursor:
                                    item.stories.length > 0 //change curser shape if no data exists
                                      ? "pointer"
                                      : "cursor",
                                }}
                              />

                              {/* Div to show text under images - controlled by switch button on left menu*/}
                              {showWords && (
                                <div className="text-dark text-center d-flex justify-content-center">
                                  {item.title}
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          // Case 4
                          <div
                            key={index}
                            className="storyset-container"
                            onClick={() => {
                              char.stories.length > 0 && handleClick(char.id);
                            }}
                          >
                            {/* Image to show active / clicked storyset */}
                            <img
                              className={
                                parseInt(stId) === char.id
                                  ? "backgroundImg fadein"
                                  : `${
                                      prevId === char.id
                                        ? "backgroundImg fadeout"
                                        : "d-none"
                                    }`
                              }
                              src={blackbg}
                              alt="Active"
                            ></img>

                            <img
                              className="imageData"
                              src={url + char.url}
                              alt="Story Set Data"
                              style={{
                                opacity:
                                  char.stories.length > 0 //show faded image if no data exists
                                    ? "1"
                                    : "0.5",
                                cursor:
                                  char.stories.length > 0 //change curser shape if no data exists
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                            />

                            {/* Div to show text under images - controlled by switch button on left menu*/}
                            {showWords && (
                              <div className="text-dark text-center d-flex justify-content-center ">
                                {char.title}
                              </div>
                            )}
                          </div>
                        )
                      )}
                  </Slider>
                </div>
              }
            </StorySetDiv>
          )}
        </div>
      </Container>
      <Outlet />
    </>
  );
};

export default StorySetSlider;

const StorySetDiv = styled.div`
  .fadein {
    animation: fadeIn 2s forwards;
  }

  .fadeout {
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .sub-sliderContainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }

  .sub-sliderContainer .slick-slider {
    height: 130px;
    padding-left: 20px;

    .slick-slide {
      outline: none;
    }
    .slick-list {
      height: 130px;
    }
    .slick-track {
      height: inherit;
    }
  }

  .slick-slide .slick-active .slick-current {
    // border: 5px solid black;
  }

  .sub-sliderContainer .slick-slide {
    // padding-left: 20px;
    // margin-left: 10px;
    outline: none;
  }

  .storyset-container {
    position: relative;
    // width: 125px;
    height: 150px;
    // margin-left: -20px;
    outline: none;
  }

  .storyset-container .imageData {
    width: 110px;
    position: absolute;
    height: 110px;
    cursor: pointer;
    // border: 5px solid red;
    z-index: 1;
    top: 10px;
    left: 10px;
  }

  .backgroundImg {
    position: absolute;
    height: 130px;
    width: 130px;
    // display: none;
  }

  .slick-prev:before,
  .slick-next:before {
    color: red !important;
    font-size: 70px;
  }

  .sub-sliderContainer .slick-track {
    margin-left: 0;
    height: 110px;
  }

  .sub-sliderContainer .slick-slider {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    height: 130px;

    .slick-prev {
      left: 15%;
    }

    .slick-next {
      right: 15%;
    }
  }

  .custom-prevbtn {
    cursor: pointer;
    opacity: 1;
    left: -6%;
    top: 30%;
    @media screen and (max-width: 1440px) {
      left: -1%;
    }
    @media screen and (max-width: 1280px) {
      left: 2%;
    }
    @media screen and (max-width: 1100px) {
      left: -3%;
    }
    @media screen and (max-width: 600px) {
      left: -10%;
    }
    @media screen and (max-width: 340px) {
      left: -20%;
    }
    &.inactive {
      display: none !important;
    }
  }

  .custom-nextbtn {
    cursor: pointer;
    opacity: 1;
    top: 30%;
    left: 101%;
    @media screen and (max-width: 1440px) {
      left: 97%;
    }
    @media screen and (max-width: 1366px) {
      left: 95%;
    }
    @media screen and (max-width: 1280px) {
      left: 93%;
    }
    &.inactive {
      display: none !important;
    }
  }

  // img{
  //   border: 5px red;
  // }
`;

const Container = styled.div`
  background-image: linear-gradient(#95c793, #86b984, #6fa16c);

  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
  padding-top: 5px;
  padding-bottom: 5px;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    /* Firefox */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fadein {
    /* Opera */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .sub-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;

    .slick-prev:before,
    .slick-next:before {
      color: red !important;
      font-size: 70px;
    }

    .slick-track {
      margin-left: 0;
      // padding-top: 10px;
      // padding-bottom: 10px;
      height: 200px;
    }

    .slick-slider {
      padding-left: 20px;
      padding-right: 20px;
      max-width: 1300px;
      margin-left: auto;
      margin-right: auto;

      .slick-prev {
        left: 15%;
      }

      .slick-next {
        right: 15%;
      }
    }

    .item {
      padding-left: 35px;
      // width: 200px;
      height: 200px;
    }
    .item img {
      // width: inherit;
      height: inherit;
      cursor: pointer;
      // padding: 0px 5px;

      // border: 2px solid black;
      // margin-right: -10px;
      // margin-top:-37px;
    }
  }
`;
