import { useContext, useState } from "react";
import { BsSearch } from "react-icons/bs";
import styled from "styled-components";
import { APIContext } from "../../../apiContext";


const SearchBar: React.FC = () => {

    const { setSearchParams } = useContext(APIContext);

    const [text, setText] = useState("");

    const onSubmit = (evt: { preventDefault: () => void; }) => {
        evt.preventDefault();
        setSearchParams({ q: text })
        setText(text)
    };

    const onChange = (evt: { target: { value: any; }; }) => setText(evt.target.value);

    return (
        <Container>
            <form onSubmit={onSubmit} className="search d-flex justify-content-center">
                <input
                    type="text"
                    name="text"
                    value={text}
                    onChange={onChange}
                    autoComplete="off"
                    className="bg-white p-2 outline-none searchbar"
                />
                <button type="submit" className="text-center">
                    <BsSearch size={40} />
                </button>
            </form>

        </Container>
    );
}

export default SearchBar;

const Container = styled.div`
button{
    background-color: Transparent;
    border-color: Transparent;
    color: white;
  }

.search{
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 5px;
    
}

.searchbar{
    width: 500px;
    border-radius: 30px;
    border: none;
}
`