import { Outlet, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import styled from "styled-components";
import arrow from "../../../assets/images/arrowRight.svg";
import diamondBlack from "../../../assets/images/diamondblack.svg";
import pentaBlack from "../../../assets/images/pentablack.png";
import { useContext, useEffect, useState } from "react";
import { APIContext } from "../../../apiContext";
import bg from "../../../assets/images/wavy-bg.svg";
import Loader from "../../Loader";
import { useGetTeamDetails } from "../../../hooks/useGetTeamDetails";

//Left Arrow for carousel
function SamplePrevArrow(props: any) {
  return (
    <img
      src={arrow}
      {...props}
      className={`custom-prevbtn ${props.onClick === null ? "inactive" : ""}`}
    ></img>
  );
}

//Right Arrow for carousel
function SampleNextArrow(props: any) {
  return (
    <img
      src={arrow}
      {...props}
      className={`custom-nextbtn ${props.onClick === null ? "inactive" : ""}`}
    ></img>
  );
}

const RightMenuBar: React.FC = () => {
  //Params from the URL
  const { label, id }: any = useParams();

  //State manage to show borders behind active image
  const [prevId, setPrevId] = useState<number>();

  let navigate = useNavigate();

  //Get data from API Context
  const {
    setMainParams,
    showWords,
    isHomeLoading,
    showStories,
    setShowStories,
    url,
  } = useContext(APIContext);

  //Get response from API
  const resp = useGetTeamDetails();

  useEffect(() => {
    if (id) {
      //in case of navigate back
      navigate(`/type/${label}/${id}`);
      setMainParams(localStorage.getItem("label")); //send API request
    }
  }, []);

  useEffect(() => {
    //in case of change of label
    localStorage.setItem("label", label);
    setMainParams({ type: label });
  }, [label]);

  //Settings for carousel
  var settings = {
    dots: false,
    slidesToShow: localStorage.getItem("label") === "doctrinal" ? 6 : 5,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
    // variableWidth: true
  };

  //Handle behaviour on image clicks
  const handleClick = (index: any) => {
    setPrevId(parseInt(id)); //Set id for previous selected item for shadow/border
    setShowStories(true); //Hide story set bigger slider
    navigate(`/type/${label}/${index}`); //Show child route (VideoSlider)
  };

  return (
    <MainDiv className="animation">
      <Container>
        <div className="sub-container">
          {isHomeLoading ? (
            <div className="pageLoaderUPs">
              <Loader />
            </div>
          ) : resp.length > 0 ? (
            <Slider {...settings}>
              {resp.map((item: any, index: any) => (
                <div className="item">
                  {localStorage.getItem("label") === "doctrinal" ? (
                    // Image to show active / clicked storyset
                    <img
                      className={
                        parseInt(id) === item.id
                          ? "backgroundImgDiamond fadein"
                          : `${
                              prevId === item.id
                                ? "backgroundImgDiamond fadeout"
                                : "d-none"
                            }`
                      }
                      src={diamondBlack}
                      alt="Active Story"
                    />
                  ) : (
                    localStorage.getItem("label") === "topical" && (
                      // Image to show active / clicked storyset
                      <img
                        className={
                          parseInt(id) === item.id
                            ? "backgroundImgPenta fadein"
                            : `${
                                prevId === item.id
                                  ? "backgroundImgPenta fadeout"
                                  : "d-none"
                              }`
                        }
                        src={pentaBlack}
                        alt="Active Story"
                      />
                    )
                  )}
                  <img
                    className="imageData"
                    src={url + item.url}
                    alt="Story Set Data"
                    onClick={() => {
                      handleClick(item.id);
                    }}
                    style={{
                      opacity: item.stories?.length > 0 ? "1" : "0.5", //show faded image if no data exists
                      cursor:
                        item.stories?.length > 0 ? "pointer" : "not-allowed", //change curser shape if no data exists
                    }}
                  />

                  {/* Div to show text under images - controlled by switch button on left menu*/}
                  {showWords && (
                    <div className="p-2 text-light text-center d-flex justify-content-center">
                      {item.title}
                    </div>
                  )}
                </div>
              ))}
            </Slider>
          ) : (
            <p className="m-auto text-center text-light">No Records Found</p>
          )}
        </div>
      </Container>
      {showStories && <Outlet />}
    </MainDiv>
  );
};

const MainDiv = styled.div`
  background-image: url(${bg});
  height: calc(100vh - 97px);
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
`;

const Container = styled.div`
  background-image: linear-gradient(#95c793, #86b984, #6fa16c);
  padding: 10px 0px;

  .fadein {
    animation: fadeIn 2s forwards;
  }

  .fadeout {
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .sub-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
  }

  .slick-prev:before,
  .slick-next:before {
    color: red !important;
    font-size: 70px;
  }

  .slick-list {
    height: 240px;
  }

  .slick-track {
    margin-left: 0;
    padding-top: 10px;
    // padding-bottom: 10px;
  }

  .slick-slider {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;

    .slick-prev {
      left: 15%;
    }

    .slick-next {
      right: 15%;
    }
  }

  .item {
    width: 200px;
    height: 200px;
    padding-left: 35px;
    position: relative;
    padding: 0px 5px;
    outline: none;
  }

  // img{
  //   width: 100%;
  //   height: 100%;
  //   padding: 0px 5px;
  // }

  .item .imageData {
    width: auto;
    position: absolute;
    height: inherit;
    cursor: pointer;
    z-index: 1;
    top: 10px;
    left: -10px;
    padding: 0px 25px;
  }

  .item .backgroundImgDiamond {
    position: absolute;
    height: 220px;
    width: 176px;
    // display: none;
  }

  .item .backgroundImgPenta {
    position: absolute;
    height: 220px;
    width: 220px;
    // display: none;
  }
`;

export default RightMenuBar;
