import styled from "styled-components";
import { useContext, useEffect, useMemo, useState } from "react";
import { APIContext } from "../../../apiContext";
import { Board } from "../Svg";
import Loader from "../../Loader";
import HomeZoomedView from "./HomeZommedView";
import Board2 from "../Svg/Icons/Board2";
import useWindowSize from 'react-use/lib/useWindowSize'



const Home: React.FC = () => {

  //Get loader, send req for API and get API response from API Context => {show loader till the response is loaded}
  const { isHomeLoading, apiRes, setMainParams } = useContext(APIContext);
  
  //Manage state for toggle button
  const [showItems, setShowItems] = useState(false);
  
  //Manage screen width size (mobile/laptop) using React-use 
  const { width } = useWindowSize();

  useEffect(() => {
    //set toggle button in local state for zoomed in effect of home page
    localStorage.setItem("ViewToggleState", JSON.stringify(showItems));
    setShowItems(showItems);
  }, [showItems]);

  useEffect(()=>{
    localStorage.setItem("label","meta-narrative")
    
    //send API req call
    setMainParams({type: "meta-narrative"})
  },[])


  //change toggle state
  const handleChange = () => { 
    if (showItems === false) {
      setTimeout(() => {
      }, 400);
      setShowItems(true);
    } else if (showItems === true) {
      setShowItems(false);
    }
  };

  return (
    <MainContainer>
      {isHomeLoading ? (
        <div className="pageLoaderUPs">
          <Loader />
        </div>
      ) : (
        <> 
        {/* Toggle Button */}
          <Div>
            <div className="row switch__container switch_container-home p-3">
              <input
                id="switch-shadow2"
                className="switch switch--shadow"
                type="checkbox"
                checked={showItems}
                onChange={() => handleChange()}
              />
              <label htmlFor="switch-shadow2"></label>
            </div>
          </Div>
          
          { !showItems ? (
            width < 768 ? (
               <div className="row p-5">
                {/* /* //SVG for mobile */ }
                {/* <p>HAHA</p> */}
             <Board2 data={apiRes}/> 
                 </div> 
             ) :
            <Board data={apiRes}/> //SVG for laptop
            // <Board/> //SVG for laptop

            ) : (
              <HomeZoomedView/> //Zoomed view
          )}
          
        </>
       )} 
    </MainContainer>
  );
};

export default Home;

const MainContainer = styled.div`
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 97px);
  display: flex;
  overflow-x:hidden;

  .toggle-view {
    width: 5px;
  }
  .pageLoaderUPs {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02020280;
  }
`;

const Div = styled.div`
  // margin-right: -15%;
  /* Estilo iOS */
  .switch__container {
    margin: 0px auto;
    width: 80px;
    position: absolute;
    right: 7px;
  }

  .switch {
    visibility: hidden;
    position: absolute;
    margin-left: -9999px;
  }

  .switch + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }

  .switch--shadow + label {
    padding: 2px;
    width: 80px;
    height: 40px;
    background-color: #dddddd;
    border-radius: 60px;
  }

  .switch--shadow + label:before,
  .switch--shadow + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: "";
  }

  .switch--shadow + label:before {
    right: 1px;
    background-color: #f1f1f1;
    border-radius: 60px;
    transition: background 0.4s;
  }
  .switch--shadow + label:after {
    width: 35px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.4s;
    // margin-left: -17px;
  }

  .switch_container-home .switch--shadow + label {
    padding: 2px;
    width: 50px;
    height: 25px;
    background-color: #dddddd;
    border-radius: 60px;
    transition: background 0.4s;
  }

  .switch_container-home {
    .switch--shadow + label:before,
    .switch--shadow + label:after {
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      content: "";
    }

    .switch--shadow + label:before {
      right: 1px;
      background-color: #f1f1f1;
      border-radius: 60px;
      transition: background 0.4s;
    }
    .switch--shadow + label:after {
      width: 24px;
      background-color: #fff;
      border-radius: 100%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      transition: all 0.4s;
    }
    .switch--shadow:checked + label:after {
      transform: translateX(23px);
    }
  }

  .switch--shadow:checked + label:before {
    background-color: #8ce196;
  }
  .switch--shadow:checked + label:after {
    transform: translateX(40px);
  }

  /* Estilo Flat */
  .switch--flat + label {
    padding: 2px;
    width: 80px;
    height: 40px;
    background-color: #dddddd;
    border-radius: 60px;
    transition: background 0.4s;
  }

  .switch--flat + label:before,
  .switch--flat + label:after {
    display: block;
    position: absolute;
    content: "";
  }
  .switch--flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #fff;
    border-radius: 60px;
    transition: background 0.4s;
  }
  .switch--flat + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 56px;
    background-color: #dddddd;
    border-radius: 52px;
    transition: margin 0.4s, background 0.4s;
  }
  .switch--flat:checked + label {
    background-color: #8ce196;
  }
  .switch--flat:checked + label:after {
    margin-left: 60px;
    background-color: #8ce196;
  }
`