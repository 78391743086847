import UnitedStates from "../../../assets/images/countries/UnitedStates.png";
import China from "../../../assets/images/countries/China.png";
import Thailand from "../../../assets/images/countries/Thailand.png";
import Mexico from "../../../assets/images/countries/Mexico.png";
import Czech from "../../../assets/images/countries/Czech.png";
import BurkinaFaso from "../../../assets/images/countries/BurkinaFaso.png";
import Hungary from "../../../assets/images/countries/Hungary.png";
import Indonesia from "../../../assets/images/countries/Indonesia.png";
import Niger from "../../../assets/images/countries/Niger.png";
import Poland from "../../../assets/images/countries/Poland.png";
import Romania from "../../../assets/images/countries/Romania.png";
import Sengal from "../../../assets/images/countries/Sengal.png";
import Columbia from "../../../assets/images/countries/Columbia.png";
import Russia from "../../../assets/images/countries/Russia.png";
import Egypt from "../../../assets/images/countries/Egypt.png";
import SierraLeone from "../../../assets/images/countries/SierraLeone.png";
import Turkey from "../../../assets/images/countries/Turkey.png";
import Ukraine from "../../../assets/images/countries/Ukraine.png";
import Croatia from "../../../assets/images/countries/Croatia.png";
import Serbia from "../../../assets/images/countries/Serbia.png";
import Slovenia from "../../../assets/images/countries/Slovenia.png";
import Gambia from "../../../assets/images/countries/Gambia.png";


import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { APIContext } from "../../../apiContext";
import { useNavigate } from "react-router-dom";

const Languages = () => {
  const {
    setCountryId,
    countryKey,
    setCountryKey,
    countryId, // to set labels according to the country id
    setCountryImg,
    setMainParams,
  } = useContext(APIContext);

  //array of country images
  var countryImages = [
    UnitedStates, // English
    China, // Chinese
    Thailand, // Thai
    Mexico, // Spanish
    Czech, // Czech
    BurkinaFaso, // French
    Hungary, // Hungarian 
    Indonesia, // Indonesian 
    Niger, // French
    Poland, // Polish
    Romania, // Romanian
    Sengal, // French
    Columbia, // Spanish
    Russia, // Russian
    Egypt, // Arabic
    SierraLeone, //English
    Turkey, // Turkish
    Ukraine, // Ukranian
    //Croatia, // Croatian
    Serbia, // Serbian
    Slovenia, // Slovenian
    Gambia // English
  ];

  // var countryNames = ['United States', 'China', 'Thailand', 'Mexico', 'Czech', 'BurkinaFaso', 'Hungary', 'Indonesia', 'Niger', 'Poland', 'Romania', 'Sengal', 'Columbia', 'Russia', 'Egypt', 'SierraLeone', 'Turkey', 'Ukraine'];

  //empty array created to store information of country in form of objects
  var countries: any[] = [];

  //Names of countries
  var myLanguage = [
    [
      //United States - English
      "United States",
      "China",
      "Thailand",
      "Mexico",
      "Czech",
      "Burkina Faso",
      "Hungary",
      "Indonesia",
      "Niger",
      "Poland",
      "Romania",
      "Senegal",
      "Colombia",
      "Russia",
      "Egypt",
      "Sierra Leone",
      "Turkey",
      "Ukraine",
    //  "Croatia",
      "Serbia",
      "Slovenia",
      "Gambia"
    ],
    [
      //China - Chinese
      "美国",
      "中国",
      "泰国",
      "墨西哥",
      "捷克",
      "布基纳法索",
      "匈牙利",
      "印度尼西亚",
      "尼日尔",
      "波兰",
      "罗马尼亚",
      "塞内加尔",
      "哥伦比亚",
      "俄羅斯",
      "埃及",
      "埃及’,塞拉利昂",
      "火鸡",
      "乌克兰",
      // "克罗地亚",
      "塞尔维亚",
      "斯洛文尼亚",
      "岡比亞"
    ],
    [
      //Thailand - Thai
      "สหรัฐอเมริกา",
      "ประเทศจีน",
      "ประเทศไทย",
      "เม็กซิโก",
      "สาธารณรัฐเช็ก",
      "บูร์กินาฟาโซ",
      "ฮังการี",
      "อินโดนีเซีย",
      "ไนเจอร์",
      "โปแลนด์",
      "โรมาเนีย",
      "เซเนกัล",
      "โคลอมเบีย",
      "รัสเซีย",
      "อียิปต์",
      "เซียร์ราลีโอน",
      "ไก่งวง",
      "ยูเครน",
      // "โครเอเชีย",
      "เซอร์เบีย",
      "สโลวีเนีย",
      "แกมเบีย"
    ],
    [ 
      // Mexico - Spanish
      "Estados Unidos",
      "China",
      "Tailandia",
      "México",
      "Chequia",
      "Burkina Faso",
      "Hungría",
      "Indonesia",
      "Níger",
      "Polonia",
      "Rumania",
      "Senegal",
      "Colombia",
      "Rusia",
      "Egipto",
      "Sierra Leona",
      "Turquía",
      "Ucrania",
      // "Croacia",
      "Serbia",
      "Eslovenia",
      "Gambia"
    ],
    [
      // Czech - Czech
      "Spojené státy",
      "Čína",
      "Thajsko",
      "Mexiko",
      "Česká republika",
      "Burkina Faso",
      "Maďarsko",
      "Indonésie",
      "Niger",
      "Polsko",
      "Rumunsko",
      "Senegal",
      "Kolumbie",
      "Rusko",
      "Egypt",
      "Sierra Leone",
      "Turecko",
      "Ukrajina",
      // "Chorvatsko",
      "Srbsko",
      "Slovinsko",
      "Gambie"
    ],
    [
      // Burkina Faso - French
      "Etats-Unis",
      "Chine",
      "Thaïlande",
      "Mexique",
      "Tchéquie",
      "Burkina Faso",
      "Hongrie",
      "Indonésie",
      "Niger",
      "Pologne",
      "Roumanie",
      "Sénégal",
      "Colombie",
      "Russie",
      "Egypte",
      "Sierra Leone",
      "Turquie",
      "Ukraine",
      // "Croatie",
      "Serbie",
      "Slovénie",
      "Gambie"
    ],
    [
      // Hungary - Humgarian
      "Egyesült Államok",
      "Kína",
      "Thaiföld",
      "Mexikó",
      "Csehország",
      "Burkina Faso",
      "Magyarország",
      "Indonézia",
      "Niger",
      "Lengyelország",
      "Románia",
      "Szenegál",
      "Colombia",
      "Oroszország",
      "Egyiptom",
      "Sierra Leone",
      "Törökország",
      "Ukrajna",
      // "Horvátország",
      "Szerbia",
      "Szlovénia",
      "Gambia"
    ],
    [
      // Indonessia - Indonessian 
      "Amerika Serikat",
      "Cina",
      "Thailand",
      "Mexico",
      "Republik Ceko",
      "Burkina Faso",
      "Hungaria",
      "Indonesia",
      "Niger",
      "Polandia",
      "Rumania",
      "Senegal",
      "Kolumbia",
      "Rusia",
      "Mesir",
      "Sierra Leone",
      "Turki",
      "Ukraina",
      // "Kroasia",
      "Serbia",
      "Slovenia",
      "Gambia"
    ],
    [
      // Niger - French
      "Etats-Unis",
      "Chine",
      "Thaïlande",
      "Mexique",
      "Tchéquie",
      "Burkina Faso",
      "Hongrie",
      "Indonésie",
      "Niger",
      "Pologne",
      "Roumanie",
      "Sénégal",
      "Colombie",
      "Russie",
      "Egypte",
      "Sierra Leone",
      "Turquie",
      "Ukraine",
      // "Croatie",
      "Serbie",
      "Slovénie",
      "Gambie"
    ],
    [
      // Poland - Polish
      "Stany Zjednoczone",
      "Chiny",
      "Tajlandia",
      "Meksyk",
      "Republika Czeska",
      "Burkina Faso",
      "Węgry",
      "Indonezja",
      "Niger",
      "Polska",
      "Rumunia",
      "Senegal",
      "Kolumbia",
      "Rosja",
      "Egipt",
      "Sierra Leone",
      "Turcja",
      "Ukraina",
      // "Chorwacja",
      "Serbia",
      "Słowenia",
      "Gambia"
    ],
    [
      //Romania - Romanian
      "Statele Unite",
      "China",
      "Tailanda",
      "Mexic",
      "Republica Cehă",
      "BurkinaFaso",
      "Ungaria",
      "Indonezia",
      "Niger",
      "Polonia",
      "România",
      "Senegal",
      "Columbia",
      "Rusia",
      "Egipt",
      "Sierra Leone",
      "Turcia",
      "Ucraina",
      // "Croația",
      "Serbia",
      "Slovenia",
      "Gambia"
    ],
    [
      // Sengal - French
      "Etats-Unis",
      "Chine",
      "Thaïlande",
      "Mexique",
      "Tchéquie",
      "Burkina Faso",
      "Hongrie",
      "Indonésie",
      "Niger",
      "Pologne",
      "Roumanie",
      "Sénégal",
      "Colombie",
      "Russie",
      "Egypte",
      "Sierra Leone",
      "Turquie",
      "Ukraine",
      // "Croatie",
      "Serbie",
      "Slovénie",
      "Gambie"
    ],
    [
      // Colombia - Spanish
      "Estados Unidos",
      "China",
      "Tailandia",
      "México",
      "Chequia",
      "Burkina Faso",
      "Hungría",
      "Indonesia",
      "Níger",
      "Polonia",
      "Rumania",
      "Senegal",
      "Colombia",
      "Rusia",
      "Egipto",
      "Sierra Leona",
      "Turquía",
      "Ucrania",
      // "Croacia",
      "Serbia",
      "Eslovenia",
      "Gambia"
    ],
    [
      // Russia - Russian
      "США",
      "Китай",
      "Таиланд",
      "Мексика",
      "Чехия",
      "Буркина-Фасо",
      "Венгрия",
      "Индонезия",
      "Нигер",
      "Польша",
      "Румыния",
      "Сенегал",
      "Колумбия",
      "Россия",
      "Египет",
      "Сьерра-Леоне",
      "Турция",
      "Украина",
      // "Хорватия",
      "Сербия",
      "Словения",
      "Гамбия"
    ],
    [
      //Egypt - Arabic
      "الولايات المتحدة الأمريكية",
      "الصين",
      "تايلاند",
      "المكسيك",
      "الجمهورية التشيكية",
      "بوركينا فاسو",
      "هنغاريا",
      "إندونيسيا",
      "النيجر",
      "بولندا",
      "رومانيا",
      "السنغال",
      "كولومبيا",
      "روسيا",
      "مصر",
      "سيرا ليون",
      "تركيا",
      "أوكرانيا",
      // "كرواتيا",
      "صربيا",
      "سلوفينيا",
      "غامبيا"
    ],
    [
      //SierraLeone - English
      "United States",
      "China",
      "Thailand",
      "Mexico",
      "Czech",
      "Burkina Faso",
      "Hungary",
      "Indonesia",
      "Niger",
      "Poland",
      "Romania",
      "Senegal",
      "Colombia",
      "Russia",
      "Egypt",
      "Sierra Leone",
      "Turkey",
      "Ukraine",
      // "Croatia",
      "Serbia",
      "Slovenia",
      "Gambia"
    ],
    [
      // Turkey - Turkish
      "Amerika Birleşik Devletleri",
      "Çin",
      "Tayland",
      "Meksika",
      "Çek",
      "Burkina Faso",
      "Macaristan",
      "Endonezya",
      "Nijer",
      "Polonya",
      "Romanya",
      "Senegal",
      "Kolombiya",
      "Rusya",
      "Misir",
      "Sierra Leone",
      "Türkiye",
      "Ukrayna",
      // "Hırvatistan",
      "Sırbistan",
      "Slovenya",
      "Gambiya"
    ],
    [
      //Ukarain - Ukaranian
      "Сполучені Штати",
      "Китай",
      "Таїланд",
      "Мексика",
      "чеська",
      "Буркіна Фасо",
      "Угорщина",
      "Індонезія",
      "Нігер",
      "Польща",
      "Romania",
      "Румунія",
      "Колумбія",
      "Росія",
      "Єгипет",
      "СьєрраЛеоне",
      "Туреччина",
      "Україна",
      // "Хорватія",
      "Сербія",
      "Словенія",
      "Гамбія"
    ],
    // [
    //   // Croatia - Croatian
    //   "Sjedinjene Države",
    //   "Kina",
    //   "Tajland",
    //   "Meksiko",
    //   "Češka",
    //   "Burkina Faso",
    //   "Mađarska",
    //   "Indonezija",
    //   "Niger",
    //   "Poljska",
    //   "Rumunjska",
    //   "Senegal",
    //   "Kolumbija",
    //   "Rusija",
    //   "Egipat",
    //   "Sierra Leone",
    //   "Turska",
    //   "Ukrajina",
    //   // "Hrvatska",
    //   "Srbija",
    //   "Slovenija",
    //   "Gambija"
    // ],
    [
      //Serbia - Serbian
      "Сједињене Државе",
      "Кина",
      "Тајланд",
      "Мексико",
      "Чешка",
      "Буркина Фасо",
      "Мађарска",
      "Индонезија",
      "Нигер",
      "Пољска",
      "Румунија",
      "Сенегал",
      "Колумбија",
      "Русија",
      "Египат",
      "Сијера Леоне",
      "Турска",
      "Украјина",
      // "Хрватска",
      "Србија",
      "Словенија",
      "Гамбија"
    ],
    [
      //Slovenia - Slovenian
      "Združene države",
      "Kitajska",
      "Tajska",
      "Mehika",
      "Češka",
      "Burkina Faso",
      "Madžarska",
      "Indonezija",
      "Niger",
      "Poljska",
      "Romunija",
      "Senegal",
      "Kolumbija",
      "Rusija",
      "Egipt",
      "Sierra Leone",
      "Turčija",
      "Ukrajina",
      // "Hrvaška",
      "Srbija",
      "Slovenija",
      "Gambija"
    ],
    [
      // Gambia - English
      "United States",
      "China",
      "Thailand",
      "Mexico",
      "Czech",
      "Burkina Faso",
      "Hungary",
      "Indonesia",
      "Niger",
      "Poland",
      "Romania",
      "Senegal",
      "Colombia",
      "Russia",
      "Egypt",
      "Sierra Leone",
      "Turkey",
      "Ukraine",
    //  "Croatia",
      "Serbia",
      "Slovenia",
      "Gambia"
    ],
  ];

  //keys used for countries to be sent in API
  var keys = [
    "us",
    "cn",
    "th",
    "mx",
    "cz",
    "bf",
    "hu",
    "id",
    "ne",
    "pl",
    "ro",
    "sn",
    "co",
    "ru",
    "eg",
    "sl",
    "tr",
    "ua",
    // "hr",
    "rs",
    "si",
    "gm"
  ];

  let navigate = useNavigate();

  //Loop for maintaining country array
  for (var i = 0; i < countryImages.length; i++) {
    countries[i] = {
      id: i,
      img: countryImages[i],
      label: myLanguage[countryId][i],
    };
  }

  useEffect(() => {
    setCountryKey(localStorage.getItem("countryKey"));
    setCountryImg(localStorage.getItem("countryImg"));
    setMainParams({ type: "meta-narrative" });

    //Dont show the country page (default index at the start) if country is already selected
    if (localStorage.getItem("countryKey") !== null) {
      navigate(`/meta-narratives`);
    }
  }, []);

  return (
    <MainDiv>
      <div className="p-3 text-center row">
        {countries.map((item, index) => (
          <div
            key={index}
            className="p-3 mb-2 text-center item col-lg-3 col-md-2 col-sm-1 col-xs-1"
          >
            <img
              src={item.img}
              onClick={() => (
                setCountryId(item.id),
                setCountryKey(keys[index]),
                localStorage.setItem("countryKey", keys[index]),
                localStorage.setItem("countryImg", countryImages[index]),
                setCountryImg(countryImages[index]),
                setMainParams({ type: "meta-narrative" }),
                navigate(`/meta-narratives`)
              )}
            />
            <p className="p-2 text-light">{item.label}</p>
          </div>
        ))}
      </div>
    </MainDiv>
  );
};

export default Languages;

const MainDiv = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    /* Firefox */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fadein {
    /* Opera */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  img {
    width: 200px;
  }

  img:hover {
    cursor: pointer;
  }
`;
