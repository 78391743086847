import { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Navbar from "react-bootstrap/Navbar";
import styled from "styled-components";
import { BsList, BsFillGrid3X3GapFill, BsXCircle } from "react-icons/bs";
import homeIcon from "../../../assets/images/home.svg";
import { APIContext } from "../../../apiContext";
import { Figure } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
// import Switch from '../../Switch';

const Header = () => {
  let navigate = useNavigate();

  //get url path to toggle between home icon and country image
  const { pathname } = useLocation();

  //Get data from API Context (right menu response, left menu response, send API request for Home page, Words under images)
  const {
    rightMenuRes,
    leftMenuRes,
    setMainParams,
    setShowWords,
    showWords,
    countryImg,
  } = useContext(APIContext);

  //Manage state to show/hide side menus
  const [show, setShow] = useState(false);

  //Manage state to check left
  const [rightMenuPlacement, setRightMenuPlacement] = useState(true);

  //Close Menu Bar
  const handleClose = () => setShow(false);

  //Hide NavBar on mobile vider
  const [showNav, setShowNav] = useState(true);

  useEffect(()=>{
    if(pathname.includes("/video/")){
      setShowNav(false)
    }
    else{
      setShowNav(true)
    }
  },[pathname])
  //handle clicks for side menus icons
  const handleClick = (showMenu: boolean, leftMenuPlacement: boolean) => {
    setShow(showMenu); //Show menu either right or left
    setRightMenuPlacement(leftMenuPlacement); //Check which icon is clicked, show corresponding menu accordingly
  };

  //handle clicks for inside of menu items
  const handleNavigation = (label: any) => {
    if (label === "search") {
      navigate(`/search`);
      setShow(false); //hide menu
    } else if (label === "settings") {
      //removing country key from local storage so that new country key can be saved
      localStorage.removeItem("countryKey"); //if it is not removed, it will never navigate to languages page
      navigate(`/`);
      setShow(false); //hide menu
    } else {
      navigate(`/type/${label}`); //according to label type, eg doctrinal, topical etc
      setMainParams({ type: label }); //send API with updated label
      setShow(false); //hide menu
    }
  };

  //handle click on home icon or country image (middle icon on navbar)
  const handleLang = (flag: boolean) => {
    localStorage.setItem("label", "meta-narrative");
    if (localStorage.getItem("countryKey") === null) {
      localStorage.setItem("countryKey", "us"); //default country
    }
    setMainParams({ type: "meta-narrative" }); //send API req

    if (flag) {
      navigate(`/meta-narratives`); //if country is selected, show home icon overall
    } else if (!flag) {
      //if on home page, show country icon
      //removing country key from local storage so that new country key can be saved
      //if it is not removed, it will never navigate to languages page
      localStorage.removeItem("countryKey");
      navigate(`/`); //navigate to languages page
    }
  };

  return (
    <>
      {showNav && <Nav className="d-flex justify-content-between">
        {/* Left Menu Bar */}
        <button onClick={() => handleClick(true, false)}>
          <BsFillGrid3X3GapFill size={30} />
        </button>
        {/* Middle Icon */}
        {pathname === "/meta-narratives" ? (
          <img src={countryImg} onClick={() => handleLang(false)} />
        ) : (
          <img src={homeIcon} onClick={() => handleLang(true)} />
        )}
        {/* Right Menu Bar */}
        <button onClick={() => handleClick(true, true)}>
          <BsList size={40} />
        </button>

        <SideMenu
          show={show}
          onHide={handleClose}
          placement={rightMenuPlacement ? "end" : "start"}
        >
          <Offcanvas.Header
            className={
              rightMenuPlacement
                ? "justify-content-end"
                : "justify-content-start"
            }
          >
            <button onClick={() => handleClose()} className="btn">
              <BsXCircle size={30} />
            </button>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* {isLoading ? <div className='pageLoaderUPs'>
              <Loader />
            </div>: */}

            {/* Word Toggle, show/hide words is intentionally hidden */}
            {rightMenuPlacement
              ? rightMenuRes.map((item: any) => (
                  <Figure className="sub-container d-table">
                    {item.title === "word-toggle" ? (
                      ""
                    ) : (
                      // <Switch/>
                      <img
                        src={item.icon}
                        onClick={() => handleNavigation(item.title)}
                      />
                    )}
                    {showWords && (
                      <div className="p-2 text-white">
                        <figcaption>{item.label}</figcaption>
                      </div>
                    )}
                  </Figure>
                ))
              : leftMenuRes.map((item: any) =>
                  item.title === "word-toggle" ? (
                    <></>
                  ) : (
                    <>
                      <Figure className="sub-container d-table">
                        <img
                          src={item.icon}
                          onClick={() => handleNavigation(item.title)}
                        />

                        {showWords && (
                          <div className="p-2 text-white">
                            <figcaption>{item.label}</figcaption>
                          </div>
                        )}
                      </Figure>
                    </>
                  )
                )}
          </Offcanvas.Body>
        </SideMenu>
      </Nav>}
    </>
  );
};

export default Header;

const Nav = styled(Navbar)`
  background-image: linear-gradient(#288b26, #3a9936, #4ba846);

  button {
    background-color: Transparent;
    border-color: Transparent;
    color: white;
    height: 80px;
  }

  img {
    width: 40px;
  }

  img:hover {
    cursor: pointer;
  }
`;

const SideMenu = styled(Offcanvas)`
  background: grey;
  max-width: 200px;

  button {
    background-color: Transparent;
    border-color: Transparent;
    color: white;
    // height: 80px;
  }

  button:hover {
    border-color: Transparent;
    color: white;
  }
  .pageLoaderUPs {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02020280;
  }

  .sub-container {
    padding-bottom: 30px;
    margin: auto;
    text-align: center;
    // display: flex;
    // max-width:
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }
`;
