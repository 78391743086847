import { useContext } from "react";
import { APIContext } from "../apiContext";

export const useGetVideos = (id:any) => {

    const { videoRes, setVideoId } = useContext(APIContext)

    setVideoId(id)

    return videoRes;
}