import { useContext } from "react";
import { APIContext } from "../apiContext";

export const useGetCharacters = (index: any) => {

  const { apiRes } = useContext(APIContext)


  var res = apiRes.filter((number: any) => number.id === parseInt(index)).map((number: any) => {
    return number.characters
  })
  
  return res;
}