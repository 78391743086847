import styled from "styled-components";

const Loader = () => {
    return(
        <Div className="row mx-0 justify-content-center pageLoaderUPs"  >
    
        <div className="loader-spinner"></div>
    
      </Div>
    );

}

export default Loader

const Div=styled.div`
.loader-spinner {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #242e47);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 10px), #000 0);
    animation: load-spin 0.8s infinite linear;
  }

.pageLoaderUPs {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02020280;
}

  @keyframes load-spin {
    to {
      transform: rotate(1turn);
    }
  }
`