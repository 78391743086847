import { useState, useEffect, createContext } from "react";
import axios from "axios";
import UnitedStates from "./assets/images/countries/UnitedStates.png"


const intialState = {
  apiRes: [],
  rightMenuRes: [],
  leftMenuRes: [],
  searchRes: [],
  videoRes: {},
  setMainParams: (props: any) => { },
  MainParams: "meta-narrative",
  setSearchParams: (props: any) => { },
  setVideoId: (props: any) => { },
  showWords: false,
  setShowWords: (props: any) => { },
  showStories: false,
  setShowStories: (props: any) => { },
  countryId: 0,
  setCountryId: (props: any) => { },
  countryKey: "us",
  setCountryKey: (props: any) => { },
  countryImg: UnitedStates ,
  setCountryImg: (props: any) => { },
  url: "https://app.deafwaybible.com/storage/icons/thumb/",
  isLoading: false,
  isHomeLoading: false
}

interface SearchProps {
  q: any
}

interface MenuProps {
  menu: any
}

interface MainProps {
  type: any
}

export const APIContext = createContext(intialState);

function APIContextProvider({ children }: any) {
  // Initialize state
  const [data, setData] = useState([]);
  const [rightMenuData, setRightMenuData] = useState([]);
  const [leftMenuData, setLeftMenuData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [videoData, setVideoData] = useState([])

  const [mainParams, setMainParams] = useState<MainProps>(
    {
      type: localStorage.getItem("label") || "meta-narrative"   
    }
  );

  const [searchParams, setSearchParams] = useState<SearchProps>(
    {
      q: ""
    }
  );

  const [videoId, setVideoId] = useState(0)

  const [showWords, setShowWords] = useState(false);
  const [showStories, setShowStories] = useState(false);
  const [countryId, setCountryId] = useState(0)
  // const [countryKey, setCountryKey] = useState<any>(localStorage.getItem('countryKey')===null ? "us" : localStorage.getItem('countryKey'))
  const [countryKey, setCountryKey] = useState<any>(localStorage.getItem('countryKey') || "us")
  const [countryImg, setCountryImg] = useState(localStorage.getItem('countryImg') || UnitedStates)
  const [baseUrl]=useState("https://app.deafwaybible.com/storage/icons/thumb/")
  const [isLoading, setIsLoading] = useState(false)
  const [isHomeLoading, setIsHomeLoading] = useState(false)
  
  // Set default country key and country image
  useEffect(()=>{
    if(localStorage.getItem('countryImg')===null)
    {
      localStorage.setItem("countryImg", UnitedStates)
    }
  },[])



  //Fetch data for Videos
  useEffect(() => {
    const getVideoData = async () => {
      // console.log(videoId)
      if (videoId !== 0) {
        try {
          setIsLoading(true)
          const result4 = await axios({
            url: `https://app.deafwaybible.com/api/get-story-video/`+videoId,
            headers: { "Access-Control-Allow-Origin": "*" },
            method: "GET",
          });
          setVideoData(result4.data.player_url)
          // console.log("RESULT", videoData)
        }
        catch (e) {
          console.log(e);
        }
        setIsLoading(false)
      }
    }
    getVideoData()
  },
    [videoId]
  )

  //Fetch data for Search
  useEffect(() => {
    const getSearchData = async () => {
      if (searchParams.q !== "" && countryKey !== "") {
        try {
          setIsLoading(true)
          const result3 = await axios({
            url: `https://app.deafwaybible.com/api/search?q=${searchParams.q}&country=${countryKey}`,
            headers: { "Access-Control-Allow-Origin": "*" },
            method: "GET",
          });
          setSearchData(result3.data)
          // console.log(mainParams.type, countryKey, "withincontext", result3.data)
        }
        catch (e) {
          console.log(e);
        }
        setIsLoading(false)
      }
    }
    getSearchData()
  },
    [searchParams, countryKey]
  )

  // Fetch data for main params
  useEffect(() => {    
    const getMainData = async () => {
      if(countryKey===null){
        setCountryKey("us")
      }

      if (countryKey !== "" && mainParams.type !== "" && countryKey!==null) {
        try {
          setIsHomeLoading(true)
          const result = await axios({
            url: `https://app.deafwaybible.com/api/get-storysets-by-type?type=${mainParams.type}&country=${countryKey}`,
            headers: { "Access-Control-Allow-Origin": "*" },
            method: "GET",
          });
          setData(result.data)
        }
        catch (e) {
          console.log(e);
        }
        setIsHomeLoading(false)
      }
    }
    getMainData()
  },
    [mainParams, countryKey]
  )

  //Fetch data for right menu bar
  useEffect(() => {
    const getRightMenuData = async () => {
      if (countryKey !== "" && countryKey !== null) {
        try {
          // setIsLoading(true)
          const result2 = await axios({
            url: `https://app.deafwaybible.com/api/get-app-settings?country=${countryKey}&menu=right`,
            headers: { "Access-Control-Allow-Origin": "*" },
            method: "GET",
            // params: {
            //   country: menuParams.country,
            //   menu: menuParams.menu
            // },
          });
          setRightMenuData(result2.data)
        }
        catch (e) {
          console.log(e);
        }
        // setIsLoading(false)

      }
    }
    getRightMenuData()
  },
    [countryKey]
  )

  //Fetch data for left menu bar
  useEffect(() => {
    const getLeftMenuData = async () => {
      if (countryKey !== "" && countryKey !== null) {
        try {
          // setIsLoading(true)
          const result2 = await axios({
            url: `https://app.deafwaybible.com/api/get-app-settings?country=${countryKey}&menu=left`,
            headers: { "Access-Control-Allow-Origin": "*" },
            method: "GET",
            // params: {
            //   country: menuParams.country,
            //   menu: menuParams.menu
            // },
          });
          setLeftMenuData(result2.data)

        }
        catch (e) {
          console.log(e);
        }
        // setIsLoading(false)

      }
    }
    getLeftMenuData()
  },
    [countryKey]
  )


  return (
    <APIContext.Provider value={{
      apiRes: data,
      rightMenuRes: rightMenuData,
      leftMenuRes: leftMenuData,
      searchRes: searchData,
      videoRes: videoData,
      setMainParams,
      MainParams: mainParams.type,
      setSearchParams,
      setVideoId,
      showWords,
      setShowWords,
      showStories,
      setShowStories,
      countryId,
      setCountryId,
      countryKey,
      setCountryKey,
      countryImg,
      setCountryImg,
      url: baseUrl,
      isLoading, 
      isHomeLoading
    }}>
      {children}
    </APIContext.Provider>
  );
}

export default APIContextProvider;