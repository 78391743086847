import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { APIContext } from "../../../apiContext";

interface TeamDetailProps {
  data: any;
}

const TeamDetailContainer: React.FC<TeamDetailProps> = ({ data }) => {
  //Get data from Team Details (Just to not to call API again)

  const { id }: any = useParams();

  let navigate = useNavigate();

  //Get data from API Context (hide/show stories, base url for images)
  const { setShowStories, url } = useContext(APIContext);

  //First time rendering from home page
  useEffect(()=>{
    if(id)
    {
      navigate(`/stories/${id}`)
    }
  },[])

  const handleClick = (index: number) => {
    navigate(`/stories/${data[index].id}`); //Show characters, if array of characters is empty -> show story sets
    setShowStories(false); //Show stories to false
  };

  return (
    <Container>
      <div className="sub-container">
        <div className="row col-md-12 col-sm-12 col-xs-12">
          <section className="main-timeline-section">
            <div className="conference-timeline-content">
              {/* Green Line on which circles are placed (show active/inactive image) */}
              <div className="conference-center-line"></div>

              {data.map((item: any, index: any) => (
                <div
                  key={index}
                  className={"timeline-article timeline-article-bottom"}
                >
                  {/* Change class of pointer on active item (from params) */}
                  <div
                    className={
                      parseInt(id) === item.id
                        ? "meta-date-active"
                        : "meta-date-top"
                    }
                  ></div>
                  <div
                    className="content-box"
                    onClick={() => {
                      data[index].characters.length > 0
                        ? handleClick(index)
                        : data[index].story_sets.length > 0 &&
                          handleClick(index);
                    }}
                  >
                    <img
                      src={url + item.url}
                      alt="Team Item Details Data"
                      style={{
                        //show faded image if no data exists
                        opacity:
                          data[index].characters.length > 0
                            ? "1"
                            : data[index].story_sets.length > 0
                            ? "1"
                            : "0.5",
                        //change curser shape if no data exists
                        cursor:
                          data[index].characters.length > 0
                            ? "pointer"
                            : data[index].story_sets.length > 0
                            ? "pointer"
                            : "not-allowed",
                      }}
                    ></img>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: black;
  overflow: auto;
  min-height: 170px;
  // margin-left:

  &:hover {
    .custom-prevbtn {
      opacity: 1;
      transition: ease 2s;
    }

    .custom-nextbtn {
      opacity: 1;
      transition: ease 2s;
    }
  }

  .sub-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }

  .row {
    margin-left: 20px;
  }

  .main-timeline-section {
    position: relative;
    margin: auto;
    height: 170px;
    min-width: 1200px;
  }

  .main-timeline-section .conference-center-line {
    position: absolute;
    height: 5px;
    transform: translateY(-50%);
    background: green;
    top: 85%;
    margin-left: 3%;
    width: 91%;
  }

  .timeline-article {
    width: 8%;
    position: relative;
    min-height: 170px;
    float: left;
    margin-right: 3px;
  }

  .conference-timeline-content {
    // padding: 0 100px;
  }

  .timeline-article .meta-date-top {
    position: absolute;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: green;
    top: 85%;
    left: 30px;
  }

  .timeline-article .meta-date-active {
    position: absolute;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: white;
    border: 6px solid green;
    top: 85%;
    left: 30px;
  }

  .timeline-article .content-box {
    // box-shadow: 2px 2px 4px 0px #c1c1c1;
    // border:1px solid green;
    // border-radius: 5px;
    background-color: #fff;
    position: absolute;
    // top: 138%;
    // margin-right: 10px;
    // left:-20%;
    // padding:10px;
    width: 90px;
    height: 90px;
    top: 130%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .timeline-article .content-box img {
    width: 75px;
    height: 75px;
    margin-top: 5px;
    margin-left: 10px;

    z-index: 1;
  }

  .timeline-article-bottom .content-box {
    top: 10%;
  }

  .timeline-article-bottom .content-box:before {
    content: " ";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    border: 15px solid transparent;
    border-top-color: white;
  }
`;

export default TeamDetailContainer;
