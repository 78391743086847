import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow from "../../../assets/images/arrowRight.svg";
import { APIContext } from "../../../apiContext";
import blackbg from "../../../assets/images/RectangleBlack.svg";

//Props from previous component
interface SliderProps {
  data: any;
  showSliderPrev: any;
}

//Left Arrow for carousel
function SamplePrevArrow(props: any) {
  return (
    <img
      src={arrow}
      {...props}
      className={`custom-prevbtn ${props.onClick === null ? "inactive" : ""}`}
    ></img>
  );
}

//Right Arrow for carousel
function SampleNextArrow(props: any) {
  return (
    <img
      src={arrow}
      {...props}
      className={`custom-nextbtn ${props.onClick === null ? "inactive" : ""}`}
    ></img>
  );
}

const TeamDetailsSlider: React.FC<SliderProps> = ({ data, showSliderPrev }) => {
  //Get params from URL to fetch data and display
  const { id, chId, stId }: any = useParams();

  //Get data from API Context (hide/show words, hide/show Stories, base url for images)
  const { showWords, setShowStories, url } = useContext(APIContext);

  //State manage for carousel show/hide
  const [showSlider, setShowSlider] = useState(showSliderPrev);

  //State manage to see if characters array has data or not
  const [charPresent, setCharPresent] = useState(true);

  //State manage to show borders behind active image
  const [prevId, setPrevId] = useState<number>();

  //Get item from local storage to see the direction of user -> going back or forward
  const [routeCheck, setRouteChec] = useState(localStorage.getItem("navigate"));

  useEffect(() => {
    //If data exists in Charaters array
    if (data[0]?.length > 0) {
      setCharPresent(true); //Display JSX elements
      setShowSlider(true); //Display big slider
      if (chId) {
        setShowSlider(false); //If route is backwards, character id is present, show small slider
      }
    }
    //If characters array is empty
    else if (data[0]?.length < 1) {
      setCharPresent(false); //Hide all JSX elements

      if (stId) {
        navigate(`/stories/${id}/0/${stId}`); //Show child route (StorySetSlider)
      } else {
        navigate(`/stories/${id}/0`); //Show child route (StorySetSlider)
      }
    }

    setShowStories(false); //Hide stories
  }, [id, data[0]]);

  useEffect(() => {
    if (chId) {
      setShowSlider(false); //Hide Slider, small slider to appear
    }

    if (routeCheck === "backward") {
      setShowSlider(false); //Hide Slider, small slider to appear
      navigate(`/stories/${id}/${chId}/${stId}`); //Show child route (VideoSlider)
    }
  }, []);

  let navigate = useNavigate();

  // Settings for React Slick Carousel - Big
  var settings = {
    dots: false,
    slidesToShow: 5,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
    // variableWidth: true
  };

  // Settings for React Slick Carousel - Small
  var settingsDiv = {
    dots: false,
    slidesToShow: 9,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      {/* Only display if data is present in characters array */}
      {charPresent && (
        <CharacterSlider>
          <div className="show-transition">
            <div className="sub-container">
              {/* Show Carousel initially and hide on click */}
              {showSlider && (
                <Slider {...settings}>
                  {data.map((char: any, index: any) =>
                    char.map((item: any, index: any) => (
                      <>
                        <div key={index} className="item">
                          <img
                            src={url + item.url}
                            alt="Characters Data"
                            onClick={() => {
                              setShowSlider(false); //Hide big slider -> show small slider
                              setShowStories(false); //Hide stories
                              navigate(`/stories/${id}/${item.id}`); //Change route on click
                            }}
                          />

                          {/* Div to show text under images - controlled by switch button on left menu*/}
                          {showWords && (
                            <div className="text-light d-flex justify-content-center">
                              {item.title}
                            </div>
                          )}
                        </div>
                      </>
                    ))
                  )}
                </Slider>
              )}
            </div>
            <div>
              {/* Carousel to be replaced with simple scrolable div */}
              {!showSlider && (
                <CharactersDiv>
                  <Slider {...settingsDiv}>
                    {/* <div className="sub-sliderContainer"> */}

                    {data.map((char: any) =>
                      char.map((item: any, index: any) => (
                        <div
                          key={index}
                          className={"character-container"}
                          onClick={() => {
                            setPrevId(parseInt(chId)); //Set Previous ID to show transition from previous character item to current
                            setShowStories(false); //Hide stories
                            navigate(`/stories/${id}/${item.id}`); //Change route on click
                          }}
                        >
                          <>
                            {/* Image to show active / clicked storyset */}
                            <img
                              className={
                                parseInt(chId) === item.id
                                  ? "backgroundImg fadein"
                                  : `${
                                      prevId === item.id
                                        ? "backgroundImg fadeout"
                                        : "d-none"
                                    }`
                              }
                              src={blackbg}
                              alt="Active"
                            />

                            <img
                              className="imageData"
                              src={url + item.url}
                              alt="Characters Data"
                            ></img>

                            {/* Div to show text under images - controlled by switch button on left menu*/}
                            {showWords && (
                              <div className="text-light text-center d-flex justify-content-center">
                                {item.title}
                              </div>
                            )}
                          </>
                        </div>
                      ))
                    )}
                    {/* </div> */}
                  </Slider>
                </CharactersDiv>
              )}
            </div>
          </div>
        </CharacterSlider>
      )}
      {/* Display child route -> Story Set Slider */}
      <Outlet />
    </>
  );
};

export default TeamDetailsSlider;

const CharactersDiv = styled.div`
overflow: auto; 

.fadein {
  animation: fadeIn 2s forwards;
}

.fadeout {
  animation: fadeOut 2s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

  .sub-sliderContainer{
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    display: flex;
    min-width: 1200px;
    padding-left: 15px;   
    height: 140px;
}

.slick-slider{
  // height: 110px;

  .slick-track {
    // height:140px;

    margin-left:0%; 
    // padding-top: 10px;
  }
  .slick-list{
    height:140px;
  }
}


  .character-container{
    margin-right: 30px;
    width: 110px;
    height: 110px;
    position: relative;
  }

  .character-container .imageData{
    position: absolute;
    width: auto;
    height: inherit;
    cursor: pointer;
    z-index: 1;
    top: 9px;
    left: 10px;
}


  }

  .backgroundImg{
    position: absolute;
    height: 164px;
    width: 130px;
    top: -14px;
    // display: none;
  }

  .custom-prevbtn{
    cursor: pointer;
    opacity: 1;
    height: 50px;
    // font-size: 60px;
    position: absolute;
    transform: rotate(180deg);
      left: -10%;
      top: 33%;
      // @media screen and (max-width: 1440px) {
      //   left: -2%;
      // }
      @media screen and (max-width: 1280px) {
        left: -3%;
      }
      @media screen and (max-width: 1100px) {
        left: -3%;
      }
      @media screen and (max-width: 600px) {
        left: -10%;
      }
      @media screen and (max-width: 340px) {
        left: -20%;
      }
      &.inactive{
        display: none !important;
      }
  }
  
  .custom-nextbtn{
    cursor: pointer;
    opacity: 1;
    position: absolute;
    height: 50px;
    top:35%;
      right: 0;
      left: 102%;
      // bottom: 50%;
      @media screen and (max-width: 1280px) {
        left: 97.5%;
      }
      // @media screen and (max-width: 1280px) {
      //   left: 97%;
      // }
      &.inactive{
        display: none !important;
      }
  }
`;

const CharacterSlider = styled.div`
background-image: linear-gradient(#68ab66, #4d7c4e, #355734);
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
padding-top: 5px;
padding-bottom: 5px;

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}
  .sub-container{
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    margin-bottom: 0px;

  }

  
  
  .item img{
    width: 100%;
    cursor: pointer;
  }

  .slick-track {
    margin-left:0%;
  }


  .slick-slider{
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    .slick-prev {
      left: 15%;
    }
    .slick-next {
      right: 15%;
    }
  }
  
  .sub-container .item{
      // padding-top: 5px;
      width: 200px;
      height: 200px;
      
  }

  // .slick-disabled { opacity: 0% !important;  }
  }

  .custom-prevbtn{
    cursor: pointer;
    opacity: 1;
    left: -4%;
    top: 30%;
    @media screen and (max-width: 1440px) {
        left: -1%;
      }
    @media screen and (max-width: 1280px) {
      left: 2%;
    }
    @media screen and (max-width: 1100px) {
      left: -3%;
    }
    @media screen and (max-width: 600px) {
      left: -10%;
    }
    @media screen and (max-width: 340px) {
      left: -20%;
    }
    &.inactive{
      display: none !important;
    }
}

.custom-nextbtn{
  cursor: pointer;
  opacity: 1;
  top:30%;
    left: 101%;
    @media screen and (max-width: 1440px) {
        left: 97%;
      }
      @media screen and (max-width: 1366px) {
        left: 95%;
      }
    @media screen and (max-width: 1280px) {
      left: 93%;
    }
    &.inactive{
      display: none !important;
    }
}



`;
