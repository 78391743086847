import { useContext } from "react"
import { APIContext } from "../apiContext"

export const useGetStorySets = (index: any, charid: any) => {

  const { apiRes } = useContext(APIContext)

  var res = apiRes.filter((number: any) => parseInt(number.id) === parseInt(index)).map((item: any) => {

    if (item.characters.length > 0) {
      var res2 = item.characters.filter((number: any) => parseInt(number.id) === parseInt(charid)).map((item: any) => {
        return item.story_sets
      })
      return res2
    }

    else if (item.characters.length < 1) {

      res2 = item.story_sets
     
      return res2
    }
  })
  return res;
}