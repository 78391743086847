import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { APIContext } from "../../../apiContext";
import { useGetTeamDetails } from "../../../hooks/useGetTeamDetails";
import useWindowSize from "react-use/lib/useWindowSize";
// import useScroll from 'react-use/lib/useScroll'


const HomeZoomedView = () => {
  const { id }: any = useParams();

  const [showItems, setShowItems] = useState(
    localStorage.getItem("ViewToggleState") == "true"
  );

  let navigate = useNavigate();

  //(hide/show stories, base url for images) from API Context
  const { setShowStories, url } = useContext(APIContext);

  //Get response data from API
  const teamDetails = useGetTeamDetails();

  //Manage screen width size (mobile/laptop) using React-use
  const { width, height } = useWindowSize();

  const {scrollX, scrollY} = window;
  useEffect(() => {
    //set toggle button in local state for normal home screen (svg)
    localStorage.setItem("ViewToggleState", JSON.stringify(showItems));
    setShowItems(showItems);
  }, [showItems]);

  // const {x,y}=useScroll();
  useEffect(() => {
  //  useScroll();
    // window.scrollTo(0, 0);
    console.log(scrollX, scrollY)
  },[])

  return (
    <Container>
      {width > 768 ? (
        <div className="animation">
          <div className="sub-container show-transition">
            <div className="row col-md-12 col-sm-12 col-xs-12">
              <section className="main-timeline-section">
                <div className="conference-timeline-content">
                  {/* Green Line on which circles are placed (show active/inactive image) */}

                  <div className="conference-center-line"></div>

                  {teamDetails.map((item: any, index: any) => (
                    <div
                      key={index}
                      className={"timeline-article timeline-article-bottom"}
                    >
                      {/* Change class of pointer on active item (from params) */}
                      <div
                        className={
                          parseInt(id) === item.id
                            ? "meta-date-active"
                            : "meta-date-top"
                        }
                      ></div>
                      <div
                        className="content-box"
                        onClick={() => {
                          setShowStories(false);

                          item.characters.length > 0
                            ? navigate(`/stories/${item.id}`)
                            : item.story_sets.length > 0 &&
                              navigate(`/stories/${item.id}`);
                        }}
                      >
                        <img
                          src={url + item.url}
                          style={{
                            //show faded image if no data exists
                            opacity:
                              item.characters.length > 0
                                ? "1"
                                : item.story_sets.length > 0
                                ? "1"
                                : "0.5",
                            cursor:
                              //change curser shape if no data exists
                              item.characters.length > 0
                                ? "pointer"
                                : item.story_sets.length > 0
                                ? "pointer"
                                : "not-allowed",
                          }}
                        ></img>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <VerticleDiv>
          {/* <div className="sub-container show-transition">
            <div className="row col-md-12 col-sm-12 col-xs-12">
              <section className="main-timeline-section">
                <div className="conference-timeline-content">

                  <div className="conference-center-line"></div>
                  
                  {teamDetails.slice(0).reverse().map((item: any, index: any) => (
                    <div
                      key={index}
                      className={"timeline-article timeline-article-bottom"}
                    >
                      <div
                        className={
                          parseInt(id) === item.id
                            ? "meta-date-active"
                            : "meta-date-top"
                        }
                      ></div>
                      <div
                        className="content-box"
                        onClick={() => {
                          setShowStories(false);

                          item.characters.length > 0
                            ? navigate(`/stories/${item.id}`)
                            : item.story_sets.length > 0 &&
                              navigate(`/stories/${item.id}`);
                        }}
                      >
                        <img
                          src={url + item.url}
                          style={{
                            //show faded image if no data exists
                            opacity:
                              item.characters.length > 0
                                ? "1"
                                : item.story_sets.length > 0
                                ? "1"
                                : "0.5",
                            cursor:
                              //change curser shape if no data exists
                              item.characters.length > 0
                                ? "pointer"
                                : item.story_sets.length > 0
                                ? "pointer"
                                : "not-allowed",
                          }}
                        ></img>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div> */}

        <section className="vertical-timeline-section">
        <div className="comments">

          {teamDetails.map((item: any, index: any) => (
            
              <div
              key={index}
                className="main-box"
                onClick={() => {
                  setShowStories(false);

                  item.characters.length > 0
                    ? navigate(`/stories/${item.id}`)
                    : item.story_sets.length > 0 &&
                      navigate(`/stories/${item.id}`);
                }}
              >
                <img
                  src={url + item.url}
                  style={{
                    //show faded image if no data exists
                    opacity:
                      item.characters.length > 0
                        ? "1"
                        : item.story_sets.length > 0
                        ? "1"
                        : "0.5",
                    cursor:
                      //change curser shape if no data exists
                      item.characters.length > 0
                        ? "pointer"
                        : item.story_sets.length > 0
                        ? "pointer"
                        : "not-allowed",
                  }}
                ></img>
              </div>
            
          ))}
        </div>
                </section>
        </VerticleDiv> 
      )}
    </Container>
  );
};

export default HomeZoomedView;

const Container = styled.div`
  height: calc(100vh - 97px);
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  // flex-direction: column-reverse;
  // justify-content: flex-end; /* CHANGED */
// align-items: flex-start;
  width: 100%;


  background: black;
  overflow: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  . {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  // overflow-x: hidden;
  // min-height: 400px;
  // margin-left:

  .sub-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 2600px;
    margin-top: 120px;
  }

  .row {
    margin-left: 70px;
  }

  .main-timeline-section {
    position: relative;
    margin: auto;
    // height:170px;
    min-width: 5500px;
  }

  .main-timeline-section .conference-center-line {
    position: absolute;
    height: 5px;
    transform: translateY(-50%);
    background: green;
    top: 175%;
    margin-left: 3%;
    width: 90%;
  }

  .timeline-article {
    // width: %;
    position: relative;
    min-height: 300px;
    float: left;
    margin-right: 450px;
  }

  .conference-timeline-content {
    // padding: 0 100px;
  }

  .timeline-article .meta-date-top {
    position: absolute;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: white;
    border: 8px solid green;
    top: 175%;
    left: 155px;
  }

  .timeline-article .meta-date-active {
    position: absolute;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: white;
    border: 6px solid green;
    top: 85%;
    left: 30px;
  }

  .timeline-article .content-box {
    // box-shadow: 2px 2px 4px 0px #c1c1c1;
    // border:1px solid green;
    // border-radius: 5px;
    background-color: #fff;
    position: absolute;
    // top: 138%;
    // margin-right: 10px;
    // left:-20%;
    // padding:10px;
    width: 350px;
    height: 350px;
    top: 135%;
  }

  .timeline-article .content-box img {
    width: 300px;
    height: 300px;
    display: block;
    z-index: 1;
    margin-left: auto;
    margin-right: 10px;
  }

  .timeline-article-bottom .content-box {
    top: 20%;
  }

  .timeline-article-bottom .content-box:before {
    content: " ";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -100px;
    border: 50px solid transparent;
    border-top-color: white;
  }

  @media screen and (max-width: 768px) {
    .sub-container {
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
    }

    .row {
      margin-left: 20px;
    }

    .main-timeline-section {
      position: relative;
      margin: auto;
      height: 170px;
      min-width: 1800px;
    }

    .main-timeline-section .conference-center-line {
      position: absolute;
      height: 5px;
      transform: translateY(-50%);
      background: green;
      top: 85%;
      margin-left: 3%;
      width: 89%;
    }

    .timeline-article {
      width: 8%;
      position: relative;
      min-height: 170px;
      float: left;
      margin-right: 3px;
    }

    .conference-timeline-content {
      // padding: 0 100px;
    }

    .timeline-article .meta-date-top {
      position: absolute;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border-radius: 100%;
      // background: green;
      background: white;
      border: 5px solid green;
      top: 85%;
      left: 30px;
    }

    .timeline-article .meta-date-active {
      position: absolute;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;-
      border-radius: 100%;
      background: white;
      border: 6px solid green;
      top: 85%;
      left: 30px;
    }

    .timeline-article .content-box {
      // box-shadow: 2px 2px 4px 0px #c1c1c1;
      // border:1px solid green;
      // border-radius: 5px;
      background-color: #fff;
      position: absolute;
      // top: 138%;
      // margin-right: 10px;
      // left:-20%;
      // padding:10px;
      width: 90px;
      height: 90px;
      top: 130%;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }

    .timeline-article .content-box img {
      width: 75px;
      height: 75px;26%
      margin-top: 5px;
      margin-left: 10px;

      z-index: 1;
    }

    .timeline-article-bottom .content-box {
      top: 10%;
    }

    .timeline-article-bottom .content-box:before {
      content: " ";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -30px;
      border: 15px solid transparent;
      border-top-color: white;
    }
  }
`;

const VerticleDiv = styled.div`
min-height: 100vh;
flex:1;
justify-content: center;


.vertical-timeline-section {
  position: relative;
  margin: auto;
  // height:170px;
  // max-width: 768px;
  width: fit-content;
}

.comments {
  margin: 50px auto 50px auto;
  padding: 0 40px 0 20px;
  // border-right: 5px solid green;
  width: fit-content;
}

.comments:before 
{ content: '';
position: absolute;
border-right: 2px green solid;
min-height: 92%;
width: 100%;
transform: translateX(-13%);
top: 4%;
// margin-left: 2%;
 }

.comments .main-box{
  // line-height: 1.5;
  // padding-bottom: 15px;
  // background-color: #fff;
  // border: 3px solid green;
  // border-radius: 0 20px;
  padding: 10px;
  position: relative;
  width: fit-content;
}

.comments img{
  height: 200px;
  width: 200px;
}

.comments .main-box:before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 5px solid green;
  position: absolute;
  top: 45%;
  right: -37px;
}

// .comments .main-box:after { 
//   content: '';
//   position: absolute;
//   border-right: 2px green solid;
//   height: 100%;
//   width: 100%;
//   transform: translateX(-50%);
//    }

// .comments .main-box:after {
//   content: "";
//   display: block;
//   width: 0;
//   height: 0;
//   border-style: solid;
//   border-width: 8px;
//   border-color: transparent #00b0ff transparent transparent;
//   position: absolute;
//   top: 16px;
//   left: -19px;
// }







// // display: flex;
// // flex-direction: column-reverse;

// .sub-container {
//   margin-left: auto;
//   margin-right: auto;
//   max-width: 1200px;
//   transform: rotate(270deg);
//   // transform-origin: 20px 100px

// }

// .row {
//   margin-left: 20px;
// }

// .main-timeline-section {
//   position: relative;
//   margin: auto;
//   // height: 170px;
//   min-width: 1200px;
//   // transform: rotate(270deg);
// }

// .main-timeline-section .conference-center-line {
//   position: absolute;
//   height: 5px;
//   transform: translateY(-50%);
//   background: green;
//   top: 100%;
//   margin-left: 3%;
//   width: 91%;
// }

// .timeline-article {
//   width: 8%;
//   position: relative;
//   // min-height: 170px;
//   float: left;
//   // margin-right: 3px;
// }

// .conference-timeline-content {
//   // padding: 0 100px;
// }

// .timeline-article .meta-date-top {
//   position: absolute;
//   transform: translateY(-50%);
//   width: 30px;
//   height: 30px;
//   border-radius: 100%;
//   background: #fff;
//   top: 100%;
//   left: 30px;
//   border: 5px solid green;
// }



// .timeline-article .meta-date-active {
//   position: absolute;
//   transform: translateY(-50%);
//   width: 30px;
//   height: 30px;
//   border-radius: 100%;
//   background: white;
//   border: 6px solid green;
//   top: 85%;
//   left: 30px;
// }

// .timeline-article .content-box {
//   // box-shadow: 2px 2px 4px 0px #c1c1c1;
//   // border:1px solid green;
//   // border-radius: 5px;
//   background-color: #fff;
//   position: absolute;
//   // top: 138%;
//   // margin-right: 10px;
//   // left:-20%;
//   // padding:10px;
//   width: 90px;
//   height: 90px;
//   top: 130%;
//   display: flex;
//   justify-content: center;
//   cursor: pointer;
// }

// .timeline-article .content-box img {
//   width: 75px;
//   height: 75px;
//   margin-top: 5px;
//   margin-left: 10px;
//   transform: rotate(90deg);
//   z-index: 1;
// }

// .timeline-article-bottom .content-box {
//   top: 10%;
// }

// .timeline-article-bottom .content-box:before {
//   content: " ";
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   bottom: -30px;
//   border: 15px solid transparent;
  // border-top-color: white;
}
`
const Div = styled.div`
  /* Estilo iOS */
  .switch__container {
    //   margin: 0px auto;
    width: 80px;
    float: right;
    position: fixed;
    right: 0;

    //   margin-bottom: 50px;
  }

  .switch {
    visibility: hidden;
    position: absolute;
    margin-left: -9999px;
  }

  .switch + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }

  .switch--shadow + label {
    padding: 2px;
    width: 80px;
    height: 40px;
    background-color: #dddddd;
    border-radius: 60px;
  }

  .switch--shadow + label:before,
  .switch--shadow + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: "";
  }

  .switch--shadow + label:before {
    right: 1px;
    background-color: #f1f1f1;
    border-radius: 60px;
    transition: background 0.4s;
  }
  .switch--shadow + label:after {
    width: 35px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.4s;
    // margin-left: -17px;
  }

  .switch_container-home .switch--shadow + label {
    padding: 2px;
    width: 50px;
    height: 25px;
    background-color: #dddddd;
    border-radius: 60px;
    transition: background 0.4s;
  }

  .switch_container-home {
    .switch--shadow + label:before,
    .switch--shadow + label:after {
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      content: "";
    }

    .switch--shadow + label:before {
      right: 1px;
      background-color: #f1f1f1;
      border-radius: 60px;
      transition: background 0.4s;
    }
    .switch--shadow + label:after {
      width: 24px;
      background-color: #fff;
      border-radius: 100%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      transition: all 0.4s;
    }
    .switch--shadow:checked + label:after {
      transform: translateX(23px);
    }
  }

  .switch--shadow:checked + label:before {
    background-color: #8ce196;
  }
  .switch--shadow:checked + label:after {
    transform: translateX(40px);
  }

  /* Estilo Flat */
  .switch--flat + label {
    padding: 2px;
    width: 80px;
    height: 40px;
    background-color: #dddddd;
    border-radius: 60px;
    transition: background 0.4s;
  }

  .switch--flat + label:before,
  .switch--flat + label:after {
    display: block;
    position: absolute;
    content: "";
  }
  .switch--flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #fff;
    border-radius: 60px;
    transition: background 0.4s;
  }
  .switch--flat + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 56px;
    background-color: #dddddd;
    border-radius: 52px;
    transition: margin 0.4s, background 0.4s;
  }
  .switch--flat:checked + label {
    background-color: #8ce196;
  }
  .switch--flat:checked + label:after {
    margin-left: 60px;
    background-color: #8ce196;
  }
`;
