import { useContext } from "react"
import { APIContext } from "../apiContext"

export const useGetStories = (index: any, charid: any, storySetid: any) => {

  const { apiRes } = useContext(APIContext)

  var res = apiRes.filter((item: any) => item.id === parseInt(index)).map((item: any) => {

    if (item.characters.length > 0) {
      var res1 = item.characters.filter((item: any) => parseInt(item.id) === parseInt(charid)).map((char: any) => {

        var res3 = char.story_sets.filter((item: any) => parseInt(item.id) === parseInt(storySetid)).map((storyset: any) => {
          return storyset.stories;
        })
        return res3[0]
      })
      return res1[0];
    }
    else if (item.characters.length < 1) {
      var res3 = item.story_sets.filter((number: any) => parseInt(number.id) === parseInt(storySetid))
        .map((storyset: any) => {
          return storyset.stories;
        })
      return res3[0]
    }
  })
  return res[0];
}