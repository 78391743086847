import GlobalStyle from './Global';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Header from './components/pages/Home/Navbar';
import { Routes, Route } from "react-router-dom"; 
import Home from './components/pages/Home/Home';
import TeamDetails from './components/pages/Details/TeamDetails';
import APIContextProvider from './apiContext';
import RightMenuBar from './components/pages/RightMenuBar/RightMenuBar';
import Search from './components/pages/Search/Search';
import Languages from './components/pages/Languages/Languages';
import HomeZoomedView from './components/pages/Home/HomeZommedView';
import Videos from './components/pages/Videos';
import StorySetSlider from './components/pages/Details/StorySetSlider';
import VideoSlider from './components/pages/Details/VideoSlider';
import MenuStories from './components/pages/RightMenuBar/MenuStories';
// import ReactGA from "react-ga4";


function App() {

  // ReactGA.initialize("G-3BJ2DJVXL5");
  
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <APIContextProvider>
        <Header></Header>
        <Routes>

          <Route path={"/meta-narratives"} element={<Home />} />
          <Route path={"/meta-narratives"} element={<HomeZoomedView />} />
          
          <Route path={"/stories/:id"} element={<TeamDetails />} >
            <Route path={":chId"} element={<StorySetSlider />}>
              <Route path={":stId"} element={<VideoSlider />} />
            </Route>
          </Route>
         
          <Route path={"/video/:id"} element={<Videos />} />

          <Route path={"/type/:label"} element={<RightMenuBar />} >
            <Route path={":id"} element={<MenuStories />}/>
          </Route>

          <Route path={"/search"} element={<Search />} />
          <Route path={"/"} element={<Languages />} />

        </Routes>
      </APIContextProvider>
    </>
  );
}

export default App;