import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow from "../../../assets/images/arrowRightGreen.svg";
import { Card } from "react-bootstrap";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetStories } from "../../../hooks/useGetStories";
import bg from "../../../assets/images/wavy-bg.svg";
import { APIContext } from "../../../apiContext";
import React from "react";

//Left Arrow for carousel
function SamplePrevArrow(props: any) {
  return (
    <img
      src={arrow}
      {...props}
      className={`custom-prevbtn ${props.onClick === null ? "inactive" : ""}`}
    ></img>
  );
}

//Right Arrow for carousel
function SampleNextArrow(props: any) {
  return (
    <img
      src={arrow}
      {...props}
      className={`custom-nextbtn ${props.onClick === null ? "inactive" : ""}`}
    ></img>
  );
}

const VideoSlider: React.FC = () => {
  //Get params from URL to fetch data and display
  const { id, chId, stId }: any = useParams();

  let navigate = useNavigate();

  //Get data from API Context (hide/show stories, set hide/show stories base url for images)
  const { showStories, setShowStories, url } = useContext(APIContext);

  //Get response from API
  const data = useGetStories(id, chId, stId);

  useEffect(() => {
    //Get item from local storage to see the direction of user -> going back or forward
    if (localStorage.getItem("navigate") === "backward") {
      setShowStories(true); //Show videos
      navigate(`/stories/${id}/${chId}/${stId}`); //Show child route (VideoSlider)
    }
  }, []);

  //Settings for React Slick Carousel
  var settings = {
    dots: false,
    slidesToShow: 4,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <Container>
      {
        <div className="sub-container">
          <Slider {...settings}>
            {data && //check if data exists
              data.length > 0 && //check if response array is not empty
              data.map((item: any) => (
                <div className="d-flex justify-content-start items">
                  <Card
                    onClick={() => {
                      navigate(`/video/${item.id}`); //show video
                      localStorage.setItem("type", "meta-narrative");
                    }}
                  >
                    <Card.Img
                      variant="top"
                      src={url + item.url}
                      alt="Video Slider Data"
                    />
                    <Card.Body className="text-white">
                      <Card.Title>{item.title}</Card.Title>
                      <Card.Text>{item.scripture}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </Slider>
        </div>
      }
    </Container>
  );
};

const Container = styled.div`
background-image: url(${bg});

-webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
-moz-box-flex: 1; /* OLD - Firefox 19- */
-webkit-flex: 1; /* Chrome */
-ms-flex: 1; /* IE 10 */
flex: 1; /* NEW, */
padding: 40px;  

animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

  .sub-container{
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    height: 100%;
  }

  .items{
    margin-right: 20px;
  }
  .card{
    border-radius: 26px;
    background-image: linear-gradient(#8cc389 , #5ba657, #3a9238);
    width: 265px;
    min-height: 265px;
    animation: fadein 2s;
    cursor: pointer;
  }

  .card-img-top{
    width: 180px;
    margin: auto;
    margin-top: -30px;
  }

  .card-body{
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    justify-content: flex-end;
    flex: unset !important;
  }

  .card-title{
    font-size: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .card-text{
    position: relative;
    bottom: 0px;
    font-size: 15px;
  }

  .slick-prev:before, .slick-next:before{
    // color:red !important;
    font-size: 70px;
  }

  .slick-list {
    // transform: translate3d(0px, 40px, 0px);
    padding-top: 30px;
  }

  .slick-slider{
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
      
      .slick-track {
        margin-left:0px;
        // top:0px;
      }

      .slick-prev {
        left: 15%;
      }
    
      .slick-next {
        right: 15%;
      }
    }
    .custom-prevbtn{
      cursor: pointer;
      opacity:1;
      left: -6%;
      top: 40%;
      @media screen and (max-width: 1440px) {
          left: -1%;
        }
      @media screen and (max-width: 1280px) {
        left: 2%;
      }
      @media screen and (max-width: 1100px) {
        left: -3%;
      }
      @media screen and (max-width: 600px) {
        left: -10%;
      }
      @media screen and (max-width: 340px) {
        left: -20%;
      }

      &.inactive{
        display: none !important;
      }
  }
  
  .custom-nextbtn{
    cursor: pointer;
    opacity: 1;
    top:40%;
      left: 102%;
      @media screen and (max-width: 1440px) {
          left: 97%;
        }
        @media screen and (max-width: 1366px) {
          left: 95%;
        }
      @media screen and (max-width: 1280px) {
        left: 93%;
      }

    &.inactive{
      display: none !important;
    }
  }



`;

export default VideoSlider;
