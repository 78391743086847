import { useNavigate, useParams } from "react-router-dom";
import { useGetVideos } from "../../hooks/useGetVideos";
import { BsArrowLeft } from "react-icons/bs";
import { useContext } from "react";
import { APIContext } from "../../apiContext";
import Loader from "../Loader";
import styled from "styled-components";
import useWindowSize from 'react-use/lib/useWindowSize'


const Videos = () => {
  const { id }: any = useParams();

  const res = useGetVideos(id);

  const { isLoading } = useContext(APIContext);

  const { width } = useWindowSize();

  let navigate = useNavigate();

  return (
    <>
      {isLoading && (
        <PageLoadDiv>
          <Loader />
        </PageLoadDiv>
      )}
      <Div
        className={ width > 768 ? "text-white m-3 web-view-arrow" : "text-white m-3 mobile-view-arrow"}
      >
        <BsArrowLeft
          size={30}
          onClick={() => {
            localStorage.setItem("navigate", "backward");
            localStorage.setItem("label", "meta-narrative");
            navigate(-1);
          }}
        >
        </BsArrowLeft>
      </Div>
      {Object.keys(res).length > 0 && (
        <Video 
          className={ width > 768 ? "web-view" : "mobile-view"}
          controls
          width="100%"
          autoPlay={true}
        >
          <source src={`${res}`} type="video/webm" />
          <source src={`${res}`} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </Video>
      )}
    </>
  );
};

export default Videos;

const Div = styled.div`
position: absolute;
z-index: 1;
width: 40px;
cursor: pointer;

&.mobile-view-arrow{
  transform: rotate(90deg);
  right: 0px;
}

&.web-view-arrow{
  left: 0px;
}
`


const PageLoadDiv = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #02020280;
`;

const Video = styled.video`

&.mobile-view{
  overflow: hidden;
  position: absolute;
  transform: rotate(90deg);
  transform-origin: bottom left;
  width: 100vh;
  margin-top: -100vw;
  object-fit: cover;
  // z-index: 4;
  visibility: visible;
  height: 100vw;
}

&.web-view{
  width: 100vw;
  height: 98vh;
}
`