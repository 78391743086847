import styled from "styled-components"
import bg from "../../../assets/images/wavy-bg.svg"
import SearchBar from "./SearchBar";
import SearchStories from "./SearchStories";


const Search: React.FC = () => {

  return (
    <MainDiv className="animation">
      <SearchBar />
      <SearchStories />
    </MainDiv>
  )
}

export default Search

const MainDiv = styled.div`
// background-image: url(${bg});
background: grey;
min-height: calc(100vh - 97px);
display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
display: -ms-flexbox; /* TWEENER - IE 10 */
display: -webkit-flex; /* NEW - Chrome */
display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
-ms-flex-direction: column;
-moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
`